import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import FooterLogo from "../svgs/white-lion-logo-stacked.svg"
import { FaFacebookF } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaLinkedinIn } from "react-icons/fa"

const Footer = () => {
  return (
    <MainFooter>
      <FooterFlex>
        <div>
          <p>
            <b>
              <a href="tel:+15124818819">(512) 344-2010</a>
            </b>
            <br />
            <a
              class="gray-color"
              href="https://goo.gl/maps/fBK67WnPbQp8K61Q6"
              target="_blank"
              rel="noreferrer"
            >
              5203 Brodie Lane
              <br />
              Sunset Valley, Texas 78745
            </a>
            <br />
            <a href="mailto:ask@wlion.com">ask@wlion.com</a>
          </p>
        </div>
        <div class="footer-icons">
          <div class="footer-logo">
            <FooterLogo />
          </div>
          <div class="footer-socials">
            <a
              href="https://www.facebook.com/whitelioninteractive/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF size={28} />
            </a>
            <a
              href="https://twitter.com/wlion"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter size={28} />
            </a>
            <a
              href="https://www.instagram.com/whitelioninteractive/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size={28} />
            </a>
            <a
              href="https://www.linkedin.com/company/white-lion-interactive"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn size={28} />
            </a>
          </div>
        </div>
        <div class="partner-agencies">
          <p>
            <b>Partner Agencies</b>
            <br />
            <a href="https://hahnpublic.com/" target="_blank" rel="noreferrer">
              Hahn Public
            </a>
            <br />
            <a href="https://apronfoodpr.com/" target="_blank" rel="noreferrer">
              Apron Food & Beverage
            </a>
            <br />
            <a
              href="https://predictivemediatraining.com/"
              target="_blank"
              rel="noreferrer"
            >
              Predictive Media Network
            </a>
          </p>
        </div>
      </FooterFlex>
      <FooterCopyright>
        <p>
          © 2021 White Lion Interactive. All Rights Reserved.{" "}
          <Link to="/privacy-policy/">Privacy Policy</Link>
        </p>
      </FooterCopyright>
    </MainFooter>
  )
}

const MainFooter = styled.footer`
  margin-top: 100px;
  @media (max-width: 767px) {
    padding-bottom: 100px;
  }
`

const FooterFlex = styled.div`
  max-width: 1340px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .footer-logo {
    width: 232px;
    height: 144px;
    margin: 0 auto;
  }
  > div {
    &:first-child {
      p {
        font-family: "Work Sans";
        color: #726862;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        b {
          font-size: 24px;
          color: #2b2520;
          a {
            color: #2b2520 !important;
          }
        }
        a {
          &.gray-color {
            color: #726862;
            text-decoration: none;
          }
          &:last-child {
            color: #db4140;
            text-decoration: none;
          }
        }
      }
    }
    &:last-child {
      p {
        font-family: "Work Sans";
        color: #726862;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: right;
        a {
          color: #726862;
          text-decoration: none;
          transition-duration: 0.3s;
          &:hover {
            color: #db4140;
          }
        }
        b {
          font-size: 20px;
          color: #2b2520;
        }
      }
    }
  }
  .footer-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    a {
      margin: 0 15px;
      color: #db4140;
      display: block;
      transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
      &:hover {
        transform: translateY(-2px);
        color: #2b2520;
      }
    }
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    > div {
      width: 100%;
      text-align: center;
      &:nth-child(2) {
        order: 1;
        margin-bottom: 30px;
        .footer-socials {
          margin-top: 30px;
        }
      }
      &:first-child {
        order: 2;
      }
      &:last-child {
        order: 3;
        p {
          text-align: center;
        }
      }
    }
  }
`

const FooterCopyright = styled.div`
  max-width: 1360px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 96px;
  p {
    text-align: center;
    font-family: "Work Sans";
    color: #bab6b1;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    a {
      color: #bab6b1;
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    margin-top: 30px;
  }
`

export default Footer
